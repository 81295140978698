<template>
  <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
            label="Name of the package"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.name.$model"
            :message="!$v.form.name.required && $v.form.name.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout mt-4">
      <div class="md-layout-item md-size-50">
        <FileUploadComponent 
         :requiredLabel="true"
         label="Upload Thumbnail Image" class="w-full"
         v-model.trim="$v.form.thumbnail.$model"
         :path="form.type+'/thumbnails'"
         @input="handleFileUpload"
         :message="!$v.form.thumbnail.required && $v.form.thumbnail.$dirty ? 'Field is required' : null"
         />
      </div>
    </div>
    <div class="md-layout mt-4">
      <div class="md-layout-item md-size-100">
         <TextEditor
         :requiredLabel="true"
         label="Course Description"
         class=""
         labelClass="text-uppercase text-sm"
         v-model.trim="$v.form.description.$model"
         :message="!$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"               
         />
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  InputFieldComponent,
  FileUploadComponent,
  TextEditor
} from "@/components"
import { required } from "vuelidate/lib/validators";

export default {
  name: "BasicInformation",

  components: {
    Button,
    InputFieldComponent,
    FileUploadComponent,
    TextEditor
  },

  data() {
    return {
      loading: false,
      form: {
        acceptTerms: false,
        organizations: ['hb53361b-06bf-495b-bddf-626fc3216310'],
       
        active: 0,
        name: "",
        thumbnail: '',
        description: '',
        type: 'PACKAGE',
        file: ''
      }
    }
  },

  methods: {
    handleFileUpload(value){
      const path = this.form.type+'/thumbnails/'+value.name;
      this.form.thumbnail = path.toLowerCase();
    },
    submit() {

      // this.form.materials = this.upload(this.form.materials);

      this.$v.$touch();
      if(this.$v.$invalid) return;


      this.form.organization_id = 'hb53361b-06bf-495b-bddf-626fc3216310';

      let formData = this.getLocal('package-create-info');
      formData = {
         ...formData,
         ...this.form
      };
      this.setLocal('package-create-info', formData);

      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'packageCreation.courseType'});
    },

    clearAll(){
      this.form = {
        acceptTerms: false,
        organizations: [],
       
        active: 0,
        name: "",
        thumbnail: '',
        description: '',
        type: 'PACKAGE',
        file: ''
      }
      let formData = this.getLocal('package-create-info');
      
      formData = {
        ...formData,
        ...this.form
      };

      this.$emit("menu", this.$route.name+'#false');
      this.setLocal('package-create-info', formData);
    }
  },

  created() {
    let existData = this.getLocal('package-create-info');
    if (existData) this.form = existData;
  },

  validations: {
    form: {
      name: {required},
      thumbnail: {required},
      description: {required}
    }
  }
}
</script>
